:root {
	--theme: #3b2138;
	--highlight: #ece1eb;
  }
*{
  font-family: 'Poppins', sans-serif;
}

  body{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }


 
nav{
	display: flex;
	justify-content:space-between;
	padding: 0px 50px;
  box-sizing: border-box;
	width: 100%;
  max-width: 1000px;
  margin: auto;
	height: 50px;
	/* color: white;
	background-color: var(--theme); */
	align-items: center;
}

.cover-text{
	display: flex;
	justify-content:right;
}

.cover-text *{

	font-weight: 400;
}

nav *{
	text-decoration: none;
	/* color: white; */
  color: black;
	margin: 10px;
	border-radius: 10px;
  font-size:medium;
	transition: background .2s ease-in-out;
}

.cover-text a:hover{
	background: var(--highlight);
}
nav img{
	height: 35px;
}
header {
width: 100vw;
}


.main-panel {
	margin: auto;
  max-width: 1000px;
	min-width: 700px;
  flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}





.tools{
  display: flex;
  height: 500px;
  flex-shrink: 1;
  min-width: 0;
  margin: 0 20px;
  justify-content: space-evenly;
}
.objects-container{
  height: inherit;
  display: flex;
  margin: 0 0px;
  flex-shrink: 1;
  min-width: 0px;
  flex-direction: column;
  align-content: space-between;
}

.objects-container > div{
  background: #eaf0f5;
  margin: 5px 0px;
  border-radius: 10px 10px;

}

/* .scroll-container::before {
	content: " ";
	position: absolute;
	right: 0;
	width: 100px;
	height: 70px;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		#eaf0f5
	);
	pointer-events: none;
  z-index: 2;
} */
.objects-container h3{
  margin: 5px;
}

.elements-list{
  display: flex;
  position: relative;
  overflow-x:scroll;
  overflow-y: hidden;
  padding-right: 70px;
}

::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}


.main-lottie-container{

  margin: auto 10px;
  flex-grow:5;
  flex-shrink: 1;
  height: min-content;
  align-content: center;
  flex-basis: 500px;
  min-width: 350px;
  max-width: 500px;
}

.colors-container{
  order: -3;
  min-width: 0;
  width: 90%;
  margin: 10px auto;
  background: #EAF0F5;
  padding: 10px;
  border-radius: 10px;
}

.color-palette-container{
  background: red;
}
.color-list{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50px;
  border-radius: 5px;
  margin: 0 5px;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  transition: transform .05s ease-in-out;
}
.color-list div{
  height: 20px ;
}

.color-list:hover{
  transform: scale(0.9);
}

.options button{
  border: none;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;

}


.options button:hover{
	transform: scale(0.9);
}

.options button:active{
	transform: scale(.9);
}

.options button{
	transition: all .05s ease-in-out;
}
.scroll-container  {
  position: relative;
}


.lottie{
  height: 350px !important;
}


.static-options svg{
  padding: 10px;
  border-radius: 10px;
}
.static-options button{
  margin: 0;
}
.title-color-container{
  display: flex;
}
.outline{
  border: 2px #80808033 solid;
  border-radius: 20px;
}

.chevron{
  border-radius: 50px;
  border: none;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.178);

}

.chevron-right{
  position: absolute;
  right: -15px;
}

.chevron-left{
  position: absolute;
  left: -15px;
}

.size-button{
  display: none;
}

.selected-btn{
  color: white;
  background-color: #F72585;
}
.selected-btn:hover{
  background-color: #F72585;
}

.download-options button{
  margin: 5px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  transition: all .2s ease-in-out;
}

.download-options {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.download-options button:hover{
  background-color: #dd72a0;

}

.download-btns a{
text-decoration: none;
background-color: #F72585;
margin: 0px 10px;
padding: 10px;
color: white;
border-radius: 10px;
}
.download-btns{
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.secondary-btn{
  border: none;
  background-color:#7209B7;
  padding: 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
.secondary-btn:hover{
  background-color:#5f0a97;

}

.primary-btn{
  border: none;
  background-color:#F72585;
  padding: 10px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.primary-btn:hover{
  background-color:#e21370;
}
.lottie-btns{
  margin: 10px auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-content: center;

}

.lottie-btns div input{
  margin: 10px;
  border: none;
  background: #EAF0F5;
  padding: 10px;
  border-radius: 5px;
}
.lottie-btns div input:focus{
  outline: none;
}

.lottie-btns div{
  margin: auto;
}

.remove-style{
  text-decoration: none;
}
.copy-font{
  color:#7209B7;
  background:none;
  border: none;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
}
.copy-clipboard{
  width: 90%;
display: flex;
justify-content: center;
}
.bottom-menu-container{
  display: flex;
  margin: 10px auto;
  justify-content:space-around;
  align-items: center;

}


.colors-input-container{
  background: #EAF0F5;
border-radius: 10px;
}

.tertiary-btn{
  background: #dce5eb;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.color-input input[type="color"] {
	border: none;
	background: none;
	padding: 8px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.color-input input[type="color"]::-webkit-color-swatch {
	border: none;
	border-radius: 50%;
	padding: 0;
}

.color-input input[type="color"]::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 50%;
	padding: 0;
}

.loading-container{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:white;
  z-index: 3;
}
.loading-lottie{
  position: relative;
  margin: auto;

}

.homepage-container{
  width: 80%;
  margin: auto;
}

.landing-container{
  margin: auto;
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 400px;
}

.landing-container a{
  margin: 0px 10px 0px 0px;
  border-radius: 20px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
}
.landing-container h1{
  font-weight: 400;
}

.landing-text{
  min-width: 380px;
  max-width: 380px;
}

.highlights-container{
  margin: auto;
  max-width: 800px;
  min-width: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.highlight{
  background: #5f0a97;
  width:200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  color: white;
  text-align: center;
  font-size: 14px;
  border-radius: 10px;
}

.highlight img{

  overflow: visible;
  width: 100px;
  padding: 20px;
}

.img-container{
  background: white;
  border-radius: 100px;
  height: 150px;
  width: 150px;
  margin: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pink{
  background: #F72585;
}

.purple{
  background: #7209B7;
}
.dark-blue{
  background: #3A0CA3;
}
.blue{
  background: #4361EE;
}
.light-blue{
  background: #4CC9F0;
}
.green{
  background: #45DD83;
}

html,body{
  height: 100%;

}

.App{
  height: 100vh;
  display: flex;
  flex-direction: column;

}
.builder{
  flex: 1 0 auto;
}
footer{
  background: #EAF0F5;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width:700px) {


  .tools{
    height: fit-content;
    flex-direction: column;
    align-content:center;
  }

  .main-panel{
    max-width: 100%;
    min-width: 0px;
  }

  .main-lottie-container{
    margin: 0px auto;
    min-width: 0px;
    max-width: 300px;
    width: 100%;
    flex-basis: auto;
  }

  .lottie{
    height: 300px !important;
  }
  
  .download-options{
    width: 100%;
  }

  .download-options button {
    font-size: 10px;

  }
  .download-btns{
    font-size: 12px;
    font-weight: 500;
  }
  .bottom-menu-container button{
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
  }
  header{
    height: 100px;
  }
 
  nav{
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    height: 100px;
    /* color: white;
    background-color: var(--theme); */
  }

  .cover-text{
    width: 100vw;
    display: flex;
    justify-content:space-around;
    height: 30px;
    align-items: center;
  }

  
  nav h3,nav p{
    margin: 0px;
  }
  .cover-text *{
    text-decoration: none;
    color: black;
    font-weight: 400;
    font-size: 15px;
    transition: background .2s ease-in-out;
    padding: 0px;
    margin: 5px;
  }
  .landing-container{
    flex-direction: column;
    height: 500px;
  }
  .landing-lottie{
    order: 1;
    width: 300px;
  }
  .landing-text{
    order: 2;
    display: flex;
    width: 100%;
    min-width: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing-text h1{
    font-size: 20px;
    text-align: center;
  }
  .landing-text a{
    font-size: 10px;
    margin-bottom: 10px;
  }
  footer{
    font-size: 10px;
  }
}

