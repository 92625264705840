

.api-page{
    max-width: 800px;
    margin: auto;
}

.api-page h3, .api-page p{

}

.medium-element{
    height: 150px;
}

.sample-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sample-container div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.code-box pre{
    border-radius: 10px;
}
.api-page input{
    outline: none;
    background-color: #ffffff;
    border-radius: 5px;
    border: none;
    padding: 5px;
    margin: 5px;
}

.api-page > div{
    background-color: #e7ebee;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    border: 2px #becdd8 solid;
}

@media screen and (max-width:700px) {
    .medium-element{
        height: 60px;
        width: 60px;
    }
    .api-page h3{
        font-size: 12px;
    }
    .api-page p{
        font-size: 10px;
    }
    .api-page label{
        font-size: 10px;
    }
    .code-box{
        font-size: 10px;
    }
    .sample-container{
        width: 100%;
    }
}